import React from 'react'
import Page from './page'
import Grid from './grid'
import ComponentNotFound from './component_not_found'
import TextWithMedia from './textWithImage/textWithMedia'
import Header from './header/header'
import MailchimpForm from './mailchimpForm/mailchimpForm'
import EmbedForm from './embedForm/embedForm'
import Navbar from './layout/navbar/navbar'
import Text from './text/text'
import Slider from './slider/slider'
import CtaText from './ctaText/ctaText'
import Gallery from './gallery/gallery'
import CodeBlock from './codeBlock/codeBlock'

const ComponentList = {
  page: Page,
  grid: Grid,
  nav_item: Navbar,
  textWithMedia: TextWithMedia,
  header: Header,
  mailchimpForm: MailchimpForm, 
  embedForm: EmbedForm, 
  textSection: Text,
  slider: Slider,
  ctaText: CtaText,
  gallery: Gallery,
  code_block: CodeBlock,
}

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components