import React from 'react'

const VideoEmbed = ({embedCode}) => {

    return (
        <div className="embed-responsive">
            <iframe src={embedCode} width="500" height="281" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
        </div>
    )
}

export default VideoEmbed