import React, { useState, useEffect } from 'react'
import AnyLink from '../../ui/AnyLink/AnyLink'
import * as Classes from './navbar.module.scss'
import ImageAll from '../../ui/imageAll/imageAll'
import LangSwitcher from '../../ui/langSwitcher/langSwitcher'
import MenuItem from './menuItem/menuItem'

const Navbar = ({ menuItems, locales, logo, currentLocale, cta }) => {

  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
  }, [])

  const [navbarExpanded, setNavbarExpanded] = useState(false)
  return (
    <div className={[Classes.navbar, navbarExpanded && Classes.navExpanded].join(' ')}>
      <div className="container">
        <div className="row">
          <div className={[Classes.navbarInner, navbarExpanded ? Classes.expanded : Classes.closed, "col col-xs-12 flex space-between"].join(' ')}>
            <div className={Classes.mobileNav}>
              <div className={Classes.navbarLogo}>
              <AnyLink link={`/${currentLocale !== 'default' ? currentLocale : ''}`}>
                <ImageAll image={logo} alt="Logo" classes={Classes.logo} fluid maxWidth="500"/>
              </AnyLink>
              { /* locales && windowWidth > '959' && 
                <LangSwitcher locales={locales} currentLocale={currentLocale}/>
              */ }
              
              </div>
              <button className={[Classes.menuToggle, "btn-unstyled"].join(' ')}aria-controls="primary-menu" aria-expanded="false" onClick={() => setNavbarExpanded(!navbarExpanded)}>
                <span className="sr-only">
                  Primær menu
                </span>
                <span className={[Classes.iconBar, Classes.topBar].join(' ')}></span>
                <span className={[Classes.iconBar, Classes.middleBar].join(' ')}></span>
                <span className={[Classes.iconBar, Classes.bottomBar].join(' ')}></span>
              </button>
            </div>
            
            {/* <img className={Classes.logo} src={logo} alt="Logo" /> */}
            <div className={Classes.mainMenu} id="primary-menu">
              <ul className="list-unstyled list-inline">
                <li>
                {/* {locales && windowWidth < '960' && 
                  <div className={Classes.langSwitcher}>
                    <LangSwitcher relative locales={locales} currentLocale={currentLocale}/>
                  </div>
                } */}
                </li>
              {menuItems && menuItems.map((m, i) => 
                <MenuItem key={i} m={m} Classes={Classes} />
              )}
              </ul>
              

              {/* <div className={Classes.cta}>
              {cta && cta.map((c, i) => 
                <AnyLink 
                  key={i}
                  classes={`btn btn-${c.primary_secondary}`}
                  link={c.link.url ? c.link.url : c.link.story.full_slug ? `/${c.link.story.full_slug}` : null} 
                  linkTitle={c.link_title}
                  external={c.link.url && true}
                  />
              )} 
              
              </div> */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar