import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import StoryblokClient from 'storyblok-js-client'
import AnyLink from '../ui/AnyLink/AnyLink'
import * as Classes from './ctaText.module.scss'

const CtaText = ({ blok }) => {
    
    const external = blok.link.linktype === "url" || blok.link.linktype === "asset" ? blok.link.url : null
    const internal = blok.link.linktype === "story" ? blok.link.story && blok.link.story.full_slug : null
    
    let Storyblok = new StoryblokClient({
        accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
    })

    const createMarkup = (text) => {
        return {
          __html: Storyblok.richTextResolver.render(text),
        }
    }

    return (
        <SbEditable content={blok}>
        <SexySection>
            <div className="row top-xs center-xs">
                <div className={["col col-xs-12 col-lg-3 text-left-xs text-right-lg", Classes.colBorder].join(' ')} style={{borderColor: `${blok.color || '#FFC400'}`}}>
                    <h2 >{blok.title}</h2>
                </div>
                <div className="col col-xs-12 col-lg-5 text-left">
                    <div className="space-xs-up" dangerouslySetInnerHTML={createMarkup(blok.text)}/>
                    {external || internal || blok.link_text ?
                        <AnyLink external={external} link={internal ? `/${internal}` : external ? external : null} linkTitle={blok.link_text} classes={"btn btn-secondary"}/>
                    : null }
                </div>
            </div>
        </SexySection>
        </SbEditable>
    )
}

export default CtaText