import React from 'react'
import SbEditable from 'storyblok-react'
import AnyLink from '../ui/AnyLink/AnyLink'
import SexySection from '../ui/sexySection/sexySection'
import ImageAll from '../ui/imageAll/imageAll'
import * as Classes from './textWithImage.module.scss'
import StoryblokClient from 'storyblok-js-client'
import VideoEmbed from '../ui/videoEmbed/videoEmbed'


const TextWithMedia = ({blok}) => {
  const external = blok.link.linktype === "url" || blok.link.linktype === "asset" ? blok.link.url : null
  const internal = blok.link.linktype === "story" ? blok.link.story && blok.link.story.full_slug : null

  const linkTitle = blok.link_text ? blok.link_text : null
  const text = blok.text && blok.text.content[0].content ? blok.text : null
  const image = blok.media.filename
  const video = blok.embed_code
  const alt = blok.media.alt
  const imgName = blok.media.name
  const mirrored = blok.mirror_section
  const bgColor = blok.bg_color

  let Storyblok = new StoryblokClient({
    accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
  })

  const createMarkup = (text) => {
    return {
      __html: Storyblok.richTextResolver.render(text),
    }
  }
  
  const textArea = (
    <>
    {text || external || internal || linkTitle ?
      <div className={["col col-xs-12", image || video ? "col-lg-6" : "col col-xs-12 col-md-8 col-lg-6"].join(' ')}  >
        <div className={[image || video ? Classes.textArea : null, image ? Classes.wImage : null].join(' ')}>
        {blok.title ?
        <h2>{blok.title}</h2>
        : null}
        <div className={external || internal || linkTitle ? "space-xs-up" : null} dangerouslySetInnerHTML={createMarkup(text)}/>
        {external || internal || linkTitle ?
            <AnyLink external={external} link={internal ? `/${internal}` : external ? external : null} linkTitle={linkTitle} classes={"btn btn-secondary"}/>
        : null }
        </div>
      </div>
    : null }
    </>
  )

  const imageArea = (
    <>
      {image || video ? 
        <div className={["col col-xs-12 col-lg-6", mirrored ? "last-xs last-sm first-lg" : null].join(' ')}>
          <div className={[Classes.imageWrapper, mirrored ? Classes.mirroredSection : null, video ? Classes.video : null].join(' ')}>
          {image ?
          
            <ImageAll fluid classes={Classes.image} maxWidth="1080" image={image} alt={alt ? alt : imgName ? imgName : "billede"}/>
          
          : video ?
          <VideoEmbed 
            embedCode={video}
            />
          : null}
          </div>
        </div>
      : null}
    </>
  )

  return (
    <SbEditable content={blok}>
      <SexySection classes={Classes.section}>
          <div className={["row center-xs", image ? "stretch-xs" : "middle-xs", bgColor ? `bg-${bgColor}` : null].join(' ')}>
            {mirrored ? imageArea : textArea}
            {mirrored ? textArea : imageArea}
          </div>
      </SexySection>
    </SbEditable>
  )
}

export default TextWithMedia

