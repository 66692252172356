// extracted by mini-css-extract-plugin
export var mainContent = "navbar-module--main-content--3xK7x";
export var fadeInMain = "navbar-module--fade-in-main--31Tj2";
export var navbar = "navbar-module--navbar--1MWGm";
export var navExpanded = "navbar-module--navExpanded--1lxAg";
export var navbarInner = "navbar-module--navbarInner--30RGO";
export var mobileNav = "navbar-module--mobileNav--1GlNa";
export var navbarLogo = "navbar-module--navbarLogo--3R84G";
export var logo = "navbar-module--logo--1rj0t";
export var mainMenu = "navbar-module--mainMenu--1VxqR";
export var menuItem = "navbar-module--menuItem--Sl3yG";
export var submenu = "navbar-module--submenu--zU2RI";
export var hasSubmenu = "navbar-module--hasSubmenu--3fgGn";
export var expanded = "navbar-module--expanded--2dbkP";
export var cta = "navbar-module--cta--lRoV5";
export var langSwitcher = "navbar-module--langSwitcher--2Zj_8";
export var menuToggle = "navbar-module--menuToggle--1-woT";
export var iconBar = "navbar-module--iconBar--1Gd4f";
export var topBar = "navbar-module--topBar--3nrOP";
export var bottomBar = "navbar-module--bottomBar--1Cyad";
export var middleBar = "navbar-module--middleBar--10VVN";
export var closed = "navbar-module--closed--32PH8";