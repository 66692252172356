import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import * as Classes from './embedForm.module.scss'


const EmbedForm = (props) => {

  const embedCode = props.blok.embed_form
  const sectionHeading = props.blok.sectionHeading

  return (
    <SbEditable content={props.blok}>
      <SexySection fullwidth classes={Classes.embedSection}>
        { sectionHeading ? 
          <div className="row center text-center space-xs-up">
            <h2>{sectionHeading}</h2>
          </div>
        : null }
      <div className={["embed-responsive", Classes.wrapper].join(' ')}>
        <iframe src={embedCode}></iframe>
      </div>
      </SexySection>
    </SbEditable>
  )
}

export default EmbedForm



