import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import Classes from './codeBlock.module.scss'
import StoryblokClient from 'storyblok-js-client'

const CodeBlock = (props) => {

  const pb = props.blok
  const iframeSrc = pb.iframe_url || null
  const fullwidth = pb.full_width || null
  const iframeHeight = pb.iframe_height || null
  const text = pb.text || null
  const blackAndWhite = pb.black_and_white || null
  const fullHeight = pb.fullHeight || null
  const smallPadding = pb.small_padding || null

  let Storyblok = new StoryblokClient({
      accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
    })

  const createMarkup = (text) => {
      return {
      __html: Storyblok.richTextResolver.render(text),
      }
  }

  return (
    <SbEditable content={pb}>
      <SexySection fullwidth={fullwidth} classes={[smallPadding ? Classes.smallPadding : null].join(' ')}>
        <div className={fullwidth ? "container" : null}>
            <div className="row center">
                <div className={["col col-xs-12 col-md-10 col-lg-8"].join(' ')}>
                    {text ? <div className="space-xs-up" dangerouslySetInnerHTML={createMarkup(text)} /> : null }
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-xs-12" data-sal="fade" data-sal-easing="ease" data-sal-duration="1000">
                {iframeSrc ?
                  <iframe src={`${iframeSrc}`} width="100%" height={fullHeight ? "1400px!important" : `${iframeHeight} + "px"`} style={ blackAndWhite ? {WebkitFilter: "greyscale(100%)", filter: "grayscale(100%)"} : null}/>
                  : null }
            </div>
        </div> 
      </SexySection>
    </SbEditable>
  )
}

export default CodeBlock

