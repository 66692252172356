import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import StoryblokClient from 'storyblok-js-client'
import * as Classes from './text.module.scss'

const Text = ({ blok }) => {
    let Storyblok = new StoryblokClient({
        accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
      })

    const column_count = blok.text_columns
    let col_text_classes = 'col-md-10 col-lg-8'
    let text_classes = ''

    if(column_count > 1) {
        text_classes = 'column-text column-count-md-2 column-count-lg-' + column_count;
        col_text_classes = 'col-md-8';
    }
    if(column_count == 3) {
        col_text_classes = 'col-md-12';
    }

    const createMarkup = (text) => {
        return {
          __html: Storyblok.richTextResolver.render(text),
        }
    }

    return (
        <SbEditable content={blok}>
        <SexySection>
            <div className="row center-xs">
                <div className={["col col-xs-12", col_text_classes].join(' ')}>
                    {blok.title ?
                        <h2 className="space-xs-up title-underline">{blok.title}</h2>
                    : null }
                    <div className={text_classes} dangerouslySetInnerHTML={createMarkup(blok.text)}/>
                </div>
            </div>
        </SexySection>
      </SbEditable>
    )
}

export default Text