import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import ImageAll from '../ui/imageAll/imageAll'
import * as Classes from './header.module.scss'
import StoryblokClient from 'storyblok-js-client'

const Header = ({ blok }) => {
  const minHeight = "600px"
  const text = blok.text ? blok.text && blok.text : null
  const image = blok.bg_image.filename ? blok.bg_image.filename : null
  const bgColor = blok.bg_color ? blok.bg_color : null

//   let Storyblok = new StoryblokClient({
//   accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
// })
  let Storyblok = new StoryblokClient({
  accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
})

  const createMarkup = (text) => {
    return {
      __html: Storyblok.richTextResolver.render(text),
    }
  }

  return (
    <SbEditable content={blok}>
      <SexySection fullWidth minHeight={minHeight} bgColor={bgColor} classes={[Classes.section, 'no-mt'].join(' ')}>
       <div className="row">
          <div className="col col-xs-12 center" style={minHeight ? {minHeight: `${minHeight}`} : null}>
            <div dangerouslySetInnerHTML={createMarkup(blok.text)} className={["text-center col-xs-12 col-md-10 col-lg-6 break-word", Classes.text].join(' ')}/>
            <ImageAll fluid fullWidth backgroundImage image={image} maxWidth="1080" classes={[Classes.bgImage].join(' ')}/>
          </div>
        </div> 
      </SexySection>
    </SbEditable>
  )
}

export default Header
