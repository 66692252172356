import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";

const AnyLink = ({ external, link, linkTitle, classes, children }) => {

  const prefix = external && !link.includes('http') && 'http://' || ''

  return (
    external && link && linkTitle ? 
      <a href={prefix + external} className={classes} target="_blank">{linkTitle || children}</a>
    : link || linkTitle || children ?
      <AniLink fade duration={0.3} hex="#663399" to={link ? `${link.replace('__home__', '')}` : null} className={classes}>{linkTitle || children}</AniLink>
    : null
  )}

export default AnyLink