import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import StoryblokClient from 'storyblok-js-client'
import ImageAll from '../ui/imageAll/imageAll'
import * as Classes from './gallery.module.scss'


const Gallery = ({ blok }) => {

    let Storyblok = new StoryblokClient({
        accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
    })

    const createMarkup = (text) => {
        return {
          __html: Storyblok.richTextResolver.render(text),
        }
    }

    console.log(blok)
    return (
        <SbEditable content={blok}>
            <SexySection>
                <div className="row">
                    <div className="col col-xs-12 col-lg-6">
                        <h2 className="title-underline">{blok.title}</h2>
                        <div className="space-xs-up" dangerouslySetInnerHTML={createMarkup(blok.text)}/>
                        
                    </div>
                </div>
                <div className="row">
                {blok.gallery && blok.gallery.length > 0 && blok.gallery.map((item, i) => (
                    <div key={i} className="col col-xs-12 col-sm-6 col-lg-3 space-big-xs-up">
                         <ImageAll fluid maxWidth="600" image={item.image.filename} alt={item.image.alt ? item.image.alt : item.title}/>
                         <h3 className="h4 title-underline">{item.title}</h3>
                         <div dangerouslySetInnerHTML={createMarkup(item.text)}/>
                    </div>      
                ))}
                </div>
            </SexySection>
        </SbEditable>
    )
}

export default Gallery