import React from 'react'
import AnyLink from '../../ui/AnyLink/AnyLink'
import ImageAll from '../../ui/imageAll/imageAll'
import * as Classes from './footer.module.scss'
import StoryblokClient from 'storyblok-js-client'


const Footer = ({text, logo}) => {

  let Storyblok = new StoryblokClient({
    accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
  })

  function createMarkup(text) {
    return {__html: Storyblok.richTextResolver.render(text)}
  }


  return (
    <footer className={["section", Classes.footer].join (' ')}>
      <div className="container">
        <div className="row middle-xs">
          <div className={["col col-xs-12 col-sm-6 col-md-3 space-xs space-sm space-md", Classes.logoCol].join(' ')}>
            <ImageAll classes="img-fluid" image={logo.filename} fluid maxWidth="500" alt={logo && logo.alt}/>
          </div>
          <div className="col col-xs-12 col-sm-6 col-md-9">
            <div dangerouslySetInnerHTML={createMarkup(text)}></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer