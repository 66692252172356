import React, {useEffect, useState} from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import StoryblokClient from 'storyblok-js-client'

import { render } from 'storyblok-rich-text-react-renderer';

const MailchimpForm = (props) => {

  const formCode = props.blok.form_code
  const text = props.blok.text



let Storyblok = new StoryblokClient({
accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
})

const createTextMarkup = (text) => {
  return {
    __html: Storyblok.richTextResolver.render(text),
  }
}

const createMarkup = (formCode) => {
  return {
    __html: formCode,
  }
}
  return (
    <SbEditable content={props.blok}>
      <SexySection>
      <div className="row center">
      <div className="col col-xs-12 col-lg-8 col-md-10">
        <div dangerouslySetInnerHTML={createTextMarkup(text)} className="space-xs-up"/> 
        <div dangerouslySetInnerHTML={createMarkup(formCode)}/>
      </div>
      </div>
      </SexySection>
    </SbEditable>
  )
}

export default MailchimpForm

