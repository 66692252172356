import React, {useEffect, useRef, useState} from 'react'
import ImageAll from '../ui/imageAll/imageAll'
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css';
import StoryblokClient from 'storyblok-js-client'
import * as Classes from './slider.module.scss'
import SexySection from '../ui/sexySection/sexySection'
import SbEditable from 'storyblok-react'

 // import Swiper JS
 import { Swiper, SwiperSlide } from 'swiper/react';
 // import Swiper styles
 import 'swiper/css';



const Slider = ({ blok }) => {

    const [value, setValue] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);


    const onChange = newValue => {
        if(value > 0 || value < blok.slides.length) {
            setValue(newValue);
            thumbsSwiper.slideTo(newValue)
        } else {
            return
        }
    }

    const leftArrowHandler = () => {
        onChange(value -1)
    }

    const rightArrowHandler = () => {
        onChange(value +1)
    }
    
    useEffect(() => {
        console.log('value', value)
        console.log('thumbsSwiper', thumbsSwiper)
    }, [value])

    let Storyblok = new StoryblokClient({
        accessToken: 'V1NkNgsfAUB4N7PrryITKQtt'
    })

      const createMarkup = (text) => {
        return {
          __html: Storyblok.richTextResolver.render(text),
        }
      }

      console.log(value)

      
    return (
        <SbEditable content={blok}>
            <section className="section">
                <div className="container">
                    <div className="row between-xs">
                        <div className="col col-sm-12 col-md-7 space-xs-up">
                            <h2 className="title-underline">{blok.title}</h2>
                            <div dangerouslySetInnerHTML={createMarkup(blok.text)}/>
                        </div>
                        <div className={["col col-sm-12 col-md-5 end-xs flex-xs middle-xs", Classes.sliderControles].join(' ')}>
                    
                            <button className={[Classes.arrowLeft, "btn-unstyled"].join(' ')} onClick={() => leftArrowHandler()} disabled={value < 1}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54"><g transform="translate(1265 4267) rotate(180)"><g transform="translate(1262 4213)"><rect width="54" height="54" rx="10" transform="translate(-51)" fill="#fff"/></g><g transform="translate(1232.382 4230.169)"><path d="M1.321,0A1.252,1.252,0,0,0,.132.911,1.516,1.516,0,0,0,.505,2.478L8.091,9.808.505,17.136a1.487,1.487,0,0,0-.489,1.35,1.361,1.361,0,0,0,.842,1.107,1.143,1.143,0,0,0,1.257-.341L10.8,10.871a1.518,1.518,0,0,0,0-2.128L2.116.353A1.166,1.166,0,0,0,1.321,0Z" transform="translate(0 0)" fill="#001DFF"/></g></g></svg>
                                <span className="sr-only">Previous</span>
                            </button>
                            {value + 1} / {blok.slides.length}
                            <button className={[Classes.arrowRight, "btn-unstyled"].join(' ')} onClick={() => rightArrowHandler()} disabled={value >= blok.slides.length - 1}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54"><g transform="translate(-1211 -4213)"><g transform="translate(1262 4213)"><rect width="54" height="54" rx="10" transform="translate(-51)" fill="#fff"/></g><g transform="translate(1232.382 4230.169)"><path d="M1.321,0A1.252,1.252,0,0,0,.132.911,1.516,1.516,0,0,0,.505,2.478L8.091,9.808.505,17.136a1.487,1.487,0,0,0-.489,1.35,1.361,1.361,0,0,0,.842,1.107,1.143,1.143,0,0,0,1.257-.341L10.8,10.871a1.518,1.518,0,0,0,0-2.128L2.116.353A1.166,1.166,0,0,0,1.321,0Z" transform="translate(0 0)" fill="#001DFF"/></g></g></svg>
                                <span className="sr-only">Next</span>                        
                            </button>
                        </div>
                    </div>
                    </div>
                    <div className="container container-fluid">
                    <div className={["row center-xs", Classes.templateSlider].join(' ')}> 
                        { blok.slides ? 
                            <Swiper
                                slidesPerView={'auto'} 
                                spaceBetween={30}
                                onSlideChange={(e) => setValue(e.activeIndex)}
                                onSwiper={setThumbsSwiper}
                                className={Classes.swiperSlider}
                                >
                                    { blok.slides.map((slide, i) => (
                                     <SwiperSlide key={i}>
                                         <ImageAll image={slide.image.filename} alt={slide.image.alt} classes={Classes.image}/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        : null }
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default Slider