import React from 'react'
import * as Classes from './sexySection.module.scss'

const SexySection = ({children, classes, bgColor, fullWidth, noPadding}) => {


  return (
    <section 
      className={["section", classes ? classes : null, bgColor ? `bg-${bgColor}` : null, bgColor ? Classes.bgColorMargin : null].join(' ')}>
      <div className={fullWidth ? "container-fluid container" : "container"}>
        {children}
      </div>
    </section>
  )

}

export default SexySection
