import React, { useEffect } from 'react'
import { CookieBanner } from '@palmabit/react-cookie-law';

const CookieConsent = ({content}) => {

  useEffect(() => {
    if(typeof document !== 'undefined') {
      const marketingCheckBox = document.querySelector('#check-marketing-cookies')
      marketingCheckBox && marketingCheckBox.click()
    }
  }, [])

  const banner = content && <CookieBanner
    message={content.message}
    wholeDomain={true}
    policyLink={`/${content.privacy_link && content.privacy_link.story && content.privacy_link.story.full_slug}`}
    privacyPolicyLinkText={content.privacy_link_title}
    necessaryOptionText={content.necessary_option_text}
    statisticsOptionText={content.statistics_option_text}
    marketingOptionText={content.marketing_option_text}
    acceptButtonText={content.accept_title}
    declineButtonText={content.decline_title}
    showDeclineButton={true}
    showPreferencesOption={false}
    // onAccept = {() => {alert('Accepted!')}}
    // onAcceptPreferences = {() => {alert('Accepted preferences!')}}
    // onAcceptStatistics = {() => {alert('Accepted statistics!')}}
    // onAcceptMarketing = {() => {alert('Accepted marketing!')}}
    styles={{
      dialog: { 
        backgroundColor: '#73CFBB', 
        position: 'fixed', 
        width: '100%', 
        bottom: 0, 
        padding: '2rem',
        zIndex: '10'
      },
      container: {
        maxWidth: '73.125rem',
        padding: '0 0.9375rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflow: 'hidden',
        position: 'relative'
      },
      message: {
        fontSize: '1rem',
        width: '36.5625rem',
        color: '#fff'
      },
      policy: {
        color: '#3383C3',
        fontSize: '1rem',
        textDecoration: 'underline',
      },
      selectPane: {
        display: 'block',
        padding: '1.875rem 0',
      },
      optionWrapper: {
        display: 'inline-block',
        position: 'relative',
        marginRight: '1.875rem'
      },
      optionLabel: {
        color: '#fff',
        fontSize: '1rem'
      },
      checkbox: {
        position: 'absolute',
        top: '25%',
        transform: 'translateY(-50%)',
        left: '0px',
        width: '14px',
        height: '14px',
        zIndex: '2',
        cursor: 'pointer'
      },
      buttonWrapper: {
        position: 'absolute',
        top: '0',
        right: '0'
      },
      button: {
        padding: '0.5rem 1.5rem',
        borderRadius: '2rem',
        color: '#fff',
        marginLeft: '1rem'
      }
    }}
  />
  return (
    
    <>
    {/* banner || null */}
    </>
  )
}
  

export default CookieConsent